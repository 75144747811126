import "aos/dist/aos.css";
import data from "../data/academics.json";
import { Avatar, Container, Grid, Text } from "@nextui-org/react";
import { AcademicCard } from "../Components/AcademicCard";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { MainCard } from "../Components/MainCard";

import "../styles/Styles.scss";

export const RutaDeConocimiento = () => {
  return (
    <Container css={{ maxWidth: "1200px", margin: "0 auto" }}>
      <Grid.Container gap={2} justify="center">
        <Grid xs={9} sm={4}>
          <MainCard />
        </Grid>
        <Grid xs={12} sm={8} css={{ direction: "column" }}>
          <Text
            h1
            css={{
              textGradient: "45deg, #2c3e50 -20%, #76807F 100%",
            }}
            weight="bold"
          >
            La aventura del conocimiento nunca termina... <br />
            comparto con todos ustedes mi trayectoria academica, cursos y videos
            favoritos.
          </Text>
        </Grid>
      </Grid.Container>
      <Grid.Container gap={2} justify="center">
        <VerticalTimeline lineColor={"#2c3e50"}>
          {data.map((proyect, index) => (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              date={proyect.date}
              contentArrowStyle={{
                borderRight: "15px solid  rgb(33, 150, 243)",
              }}
              icon={
                <Avatar
                  color="gradient"
                  bordered
                  src={require(`../assets/icons/${proyect.type}.png`)}
                  size="18"
                  zoomed
                />
              }
            >
              <Grid key={index} xs={12} sm={12}>
                <AcademicCard
                  titleCertification={proyect.titulo}
                  urlSiteCertification={proyect.urlCertificacion}
                  imgCertification={proyect.imgTitulo}
                  urlVideo={proyect.urlVideo}
                />
              </Grid>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </Grid.Container>
    </Container>
  );
};
