import React from "react";
import { Card, Grid, Text, Image, Row, Spacer } from "@nextui-org/react";
import { Link } from "react-router-dom";
import { ReactComponent as LinkedinIcon } from "../assets/icons/LinkedinIcon.svg";
import { ReactComponent as GitHubIcon } from "../assets/icons/GitHubIcon.svg";
import { ReactComponent as GmailIcon } from "../assets/icons/GmailIcon.svg";
import imageProfile from "../assets/ProfilePic.webp";
import "../styles/generalStyles.scss";

export const DevelopCards = ({ ...info }) => {
  // const imgURL = (url) => {
  //   return new URL(`../assets/developers/${url}.jpg`, import.meta.url).href;
  // };

  return (
    <>
      <Grid css={{ padding: "1rem" }}>
        <Row
          justify="center"
          css={{
            marginBottom: "-60px",
            zIndex: "5",
          }}
        >
          <Image
            src={imageProfile}
            alt="Product Image"
            objectFit="cover"
            css={{
              width: "100%",
              height: "100%",
              borderRadius: "180%",
              filter: "none",
              boxShadow: "rgb(174 174 179) 1px -1px 5px 1px",
              "&:hover": {
                transform: "scale(1.006)",
              },
            }}
          />
        </Row>
        <Card
          isHoverable
          css={{
            w: "100%",
            alignContent: "center",
          }}
        >
          <Card.Header css={{}}>
            <Spacer y={1} />
          </Card.Header>
          <Card.Body
            css={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              padding: "5px",
              alignItems: "center",
            }}
          >
            <Text
              css={{
                color: "rgb(80 84 86)",
                marginBottom: "0",
                marginTop: "0.5rem",
              }}
              weight="bold"
            >
              Jaime Sanchez Galvis
            </Text>
          </Card.Body>
          <Card.Footer
            css={{
              textAlign: "center",
              justifyContent: "center",
              alignItems: "flex-start",
              padding: "0 10px 10px 10px",
              width: "100%",
            }}
          >
            <Row
              align="center"
              justify="center"
              css={{
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Text
                css={{
                  color: "rgb(80 84 86)",
                  margin: "0",
                }}
              >
                Desarrollador FullStack
              </Text>
              <Row
                justify="center"
                align="center"
                css={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  marginTop: "10px",
                  flexDirection: "row",
                }}
              >
                <Link
                  to="https://www.linkedin.com/in/jaimesanchezgalvis/"
                  target="_blank"
                >
                  <LinkedinIcon />
                </Link>
                <Link
                  to="https://github.com/Jaimesanchezgalvis"
                  target="_blank"
                >
                  <GitHubIcon />
                </Link>
                <Link to="mailto:jaimesanchezgalvis@gmail.com" target="_blank">
                  <GmailIcon />
                </Link>
              </Row>
            </Row>
          </Card.Footer>
        </Card>
      </Grid>
    </>
  );
};
