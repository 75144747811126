import "bootstrap/scss/bootstrap.scss";
import "aos/dist/aos.css";
import { Container, Grid, Text } from "@nextui-org/react";
import { ProyectsCard } from "../Components/ProyectsCard";

import data from "../data/proyects.json";

export const Home = () => {
  return (
    <Container css={{ maxWidth: "1200px", margin: "0 auto" }}>
      <Grid.Container gap={2} justify="center">
        <Grid css={{ direction: "column", width: "100%" }}>
          <Text
            h2
            css={{
              color: "#2c3e50",
              marginTop: "1rem",
              textAlign: "left",
            }}
            weight="bold"
          >
            Acerca de mí:
          </Text>
        </Grid>

        <Grid css={{ direction: "column" }}>
          <Text>
            Soy un ingeniero electrónico especializado en gestión de proyectos,
            con más de 10 años de experiencia en el mundo online.
          </Text>
          <Text>
            En ACN desde 2011 he estado trabajando en línea, comenzando como
            responsable de la expansión de tiendas en línea y luego
            evolucionando hacia el desarrollo de software Full-Stack.
          </Text>
          <Text>
            Como freelance desde 2019 estuve desarrollando software y realizando
            el mantenimiento de aplicaciones web. Mi enfoque en la calidad del
            código y la experiencia del usuario me ha llevado a trabajar con
            tecnologías de vanguardia como React, TypeScript, Node.js, MongoDB y
            GraphQL.
          </Text>
          <Text>
            En 2022 estuve en ACCT, una consultora de software líder en el
            mercado, donde tuve la oportunidad de aprender de expertos en el
            campo de VTEX y trabajar en proyectos desafiantes y emocionantes.
            Allí adquirí habilidades técnicas valiosas y también aprendí la
            importancia del trabajo en equipo, la comunicación y el enfoque en
            los resultados.
          </Text>
          <Text>
            En 2023 en TryCatch me he desempeñado como team lead de un equipo de
            desarrollo de software, brindando soluciones personalizadas a una
            variedad de clientes. Esta experiencia me ha permitido liderar
            proyectos interesantes y desafiantes, y también me ha dado la
            oportunidad de seguir aprendiendo y mejorando mis habilidades.
          </Text>
          <Text>
            Estoy comprometido con el aprendizaje continuo y siempre estoy
            buscando nuevas oportunidades para crecer y mejorar. Me encanta
            enfrentar nuevos desafíos y trabajar en equipo para lograr
            resultados exitosos.!
          </Text>
        </Grid>

        <Grid css={{ direction: "column", width: "100%" }}>
          <Text
            h3
            css={{
              color: "#2c3e50",
              marginTop: "1rem",
              textAlign: "left",
            }}
            weight="bold"
          >
            Estos son algunos de los proyectos que he realizado, como freelance
            y en equipo:
          </Text>
        </Grid>
      </Grid.Container>
      <Grid.Container gap={2} justify="center">
        {data.map((proyect, index) => (
          <Grid key={index} xs={12} sm={4}>
            <ProyectsCard
              descriptionSite={proyect.descriptionSite}
              urlSite={proyect.urlSite}
            />
          </Grid>
        ))}
      </Grid.Container>
    </Container>
  );
};
