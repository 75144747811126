import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { ImFilePdf } from "react-icons/im";
import Spanishcv from "../assets/Spanishcv.pdf";
import Englishcv from "../assets/Englishcv.pdf";
import { DevelopCards } from "../Components/DevelopCards";
import { Grid } from "@nextui-org/react";

import "../styles/contactame.scss";

export const Contactame = () => {
  const today = new Date();
  const year = today.getFullYear();

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <div className="container">
        <Grid.Container
          gap={2}
          alignItems="center"
          justify="center"
          css={{
            padding: "1rem",
            width: "100%",
            maxW: "1200px",
            margin: "0 auto",
          }}
        >
          <Grid
            css={{
              padding: "0.5rem",
              width: "100%",
              margin: "0 auto",
              maxW: "250px",
            }}
          >
            <DevelopCards />
          </Grid>
        </Grid.Container>

        {/* <div className="row">
          <div className="title col-12">
            <h3>Redes Sociales:</h3>
          </div>
          <div className="col-6 col-md-3" data-aos="fade-down-left">
            <a
              className="linkedin"
              href="https://www.linkedin.com/in/jaimesanchezgalvis/"
            >
              <TiSocialLinkedinCircular className="iconos li" />
            </a>
          </div>
          <div className="col-6 col-md-3" data-aos="fade-down-right">
            <a className="Gitg" href="https://github.com/Jaimesanchezgalvis">
              <AiFillGithub className="iconos git" />
            </a>
          </div>
          <div className="col-6 col-md-3" data-aos="fade-down-left">
            <a
              className="insta"
              href="https://www.instagram.com/jaimesanchezgalvis/"
            >
              <FiInstagram className="iconos fac" />
            </a>
          </div>
          <div className="col-6 col-md-3" data-aos="fade-down-right">
            <a className="what" href="https://wa.me/34722116357">
              <FaWhatsapp className="iconos wat" />
            </a>
          </div>
        </div> */}

        <div className="row cv">
          <h4>CV {year}:</h4>
          <div className="spanish">
            <a className="cvs" href={Spanishcv}>
              <ImFilePdf className="iconoscv" />
              <p>Español</p>
            </a>
          </div>
          <div className="english">
            <a className="cvs" href={Englishcv}>
              <ImFilePdf className="iconoscv" />
              <p>English</p>
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h5 className="title">Jaime Sanchez Galvis</h5>
            <a href="mailto:jaimesanchezgalvis@gmail.com">
              jaimesanchezgalvis@gmail.com
            </a>
            <h6>+34 722 116 357</h6>
          </div>
        </div>
      </div>
    </>
  );
};
