import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Button } from "../button/Button";
import ProfilePic from "../../../assets/ProfilePic.webp";

import "./Navbar.scss";
import { User } from "@nextui-org/react";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <IconContext.Provider value={{ color: "#2C3E50" }}>
        <div className="contenedornav">
          <div className="contenedornav--logosiz">
            <Link
              to="/"
              className="contenedornav-logo"
              onClick={closeMobileMenu}
            >
              <User
                src={ProfilePic}
                name="Jaime Sanchez Galvis"
                description="Front-End Developer"
                pointer
                zoomed
                size="xl"
                squared
              >
                <User.Link href="https://jaimesanchezgalvis.com"></User.Link>
              </User>
            </Link>
          </div>

          <div className="contenedornav--iconomenu" onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </div>
          <div className="contenedornav--container">
            <ul
              className={
                click
                  ? "contenedornav--nav-menu active"
                  : "contenedornav--nav-menu"
              }
            >
              <li className="nav-item">
                <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                  <h4>Home</h4>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/ruta"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  <h4>Ruta de aprendizaje</h4>
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link
                  to="/reconocimiento"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  <h4>Reconocimiento</h4>
                </Link>
              </li> */}

              {/* <li className="nav-item">
                <Link
                  to="/acercademi"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  <h4>Acerca de mi</h4>
                </Link>
              </li> */}

              {/* <li className='nav-item'>
                <Link to='/proyectos'
                  className='nav-links'
                  onClick={closeMobileMenu}
                ><h4>Proyectos</h4>
                </Link>
              </li> */}

              <li className="nav-btn">
                {button ? (
                  <Link to="/contactame" className="btn-link">
                    <Button buttonStyle="btn--outline">Contactame</Button>
                  </Link>
                ) : (
                  <Link to="/contactame" className="btn-link">
                    <Button
                      buttonStyle="btn--outline"
                      buttonSize="btn--mobile"
                      onClick={closeMobileMenu}
                    >
                      Contactame
                    </Button>
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
