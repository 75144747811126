import React, { useState, useEffect } from "react";
import { Card, Link, Text } from "@nextui-org/react";

import "./Footer.scss";
function Footer() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const year = new Date().getFullYear();

  return (
    <Card
      className={scrollPosition > 0 ? "hide" : ""}
      isHoverable
      css={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "4rem",
        position: "fixed",
        bottom: "0",
      }}
    >
      <Text>
        Copyright © {year}{" "}
        <Link color="primary" href="https://jaimesanchezgalvis.com">
          Jaime Sanchez Galvis
        </Link>
      </Text>
    </Card>
  );
}

export default Footer;
