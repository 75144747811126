import { Card, Text, Image, Link } from "@nextui-org/react";

import "./proyectsCards.scss";

export const AcademicCard = ({
  titleCertification,
  urlSiteCertification,
  imgCertification,
  urlVideo,
}) => {
  return (
    <Card isHoverable css={{ w: "100%" }}>
      <Card.Body css={{ height: "100%", paddingBottom: "0" }}>
        <Link
          href={urlSiteCertification}
          target="blank"
          css={!urlSiteCertification && { pointerEvents: "none" }}
        >
          {imgCertification ? (
            <Image
              width={"100%"}
              height={"100%"}
              src={require(`../assets/certificados/${imgCertification}.jpeg`)}
              alt="Default Image"
              objectFit="cover"
              css={{
                borderRadius: "10px",
                boxShadow: "20px 20px 60px #cccccf, -20px -20px 60px #ffffff",
              }}
            />
          ) : (
            <iframe
              title="reconocimiento rotterdam"
              className="embed-responsive-item"
              src={urlVideo}
              allowFullScreen
            ></iframe>
          )}
        </Link>
      </Card.Body>
      <Card.Footer
        css={{
          paddingTop: "0px",
        }}
      >
        <Text
          color="#2c3e50"
          size={13}
          css={{ overflow: "hidden", height: "40px", textAlign: "center" }}
        >
          {titleCertification}
        </Text>
      </Card.Footer>
    </Card>
  );
};
