import { Card, Col, Row, Button, Text } from "@nextui-org/react";

import "./proyectsCards.scss";

export const ProyectsCard = ({ descriptionSite, urlSite }) => {
  return (
    <Card isHoverable css={{ w: "100%" }}>
      <Card.Body css={{ height: "100%" }}>
        <iframe
          className="scroll-custom"
          title="ArcaPlanet"
          src={urlSite}
          width="100%"
          height="100%"
          scrolling="no"
        >
          <div className="addcontent"></div>
        </iframe>
      </Card.Body>
      <Card.Footer
        isBlurred
        css={{
          position: "absolute",
          bgBlur: "#ffffff66",
          borderTop: "$borderWeights$light solid rgba(255, 255, 255, 0.2)",
          bottom: 0,
          zIndex: 1,
        }}
      >
        <Row justify="center" css={{ height: "40px", margin: "0 auto" }}>
          <Col>
            <Text
              color="#2c3e50"
              size={13}
              css={{ overflow: "hidden", height: "40px", textAlign: "center" }}
            >
              {descriptionSite}
            </Text>
          </Col>
          <Col>
            <Row justify="center">
              <Button flat auto rounded color="secondary">
                <a href={urlSite} target="blank">
                  <Text
                    css={{ color: "inherit" }}
                    size={12}
                    weight="bold"
                    transform="uppercase"
                  >
                    Visitar
                  </Text>
                </a>
              </Button>
            </Row>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};
