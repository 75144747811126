import { Home, RutaDeConocimiento, Contactame } from "../pages";
import Navbar from "../Components/ui/navbar/Navbar";
import Footer from "../Components/ui/footer/Footer";
import { Route, Routes } from "react-router-dom";
import { Container } from "@nextui-org/react";

export const AppRouter = () => {
  return (
    <>
      <Navbar />
      <Container
        css={{
          width: "100%",
          height: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "5rem",
          paddingTop: "0.5rem",
        }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contactame" element={<Contactame />} />
          <Route path="/ruta" element={<RutaDeConocimiento />} />
        </Routes>
      </Container>
      <Footer />
    </>
  );
};
