import { Card, Col, Row, Button, Text } from "@nextui-org/react";
import ProfilePic from "../assets/ProfilePic.webp";

export const MainCard = () => {
  return (
    <Card css={{ w: "100%" }}>
      <Card.Body css={{ p: 0 }}>
        <Card.Image
          src={ProfilePic}
          width="100%"
          height="100%"
          objectFit="cover"
          alt="Card example background"
        />
      </Card.Body>
      <Card.Footer
        isBlurred
        css={{
          position: "absolute",
          bgBlur: "#ffffff66",
          borderTop: "$borderWeights$light solid rgba(255, 255, 255, 0.2)",
          bottom: 0,
          zIndex: 1,
        }}
      >
        <Row>
          <Col>
            <Text
              color="#2c3e50"
              size={12}
              css={{
                overflow: "hidden",
                height: "35px",
                width: "100%",
                margin: "0",
              }}
            >
              Bienvenid@ gracias por visitar mi sitio web.
            </Text>
          </Col>
          <Col>
            <Row justify="center">
              <a href="https://wa.me/34722116357" target="blank">
                <Button flat auto rounded color="secondary">
                  <Text
                    css={{ color: "inherit" }}
                    size={12}
                    weight="bold"
                  >
                    whatsapp
                  </Text>
                </Button>
              </a>
            </Row>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};
