import ReactDOM from "react-dom/client";
import { AppRouter } from "./router/AppRouter";
import { HashRouter } from "react-router-dom";

const rootElement = document.getElementById("root");
ReactDOM.createRoot(rootElement).render(
  <HashRouter>
    <AppRouter />
  </HashRouter>
);
// 